import React, { useState, useEffect } from "react";
import { api_url } from "../utils/url";
import {
    getEncodedId,
    POSITIONS_TAB,
    TASKS_TAB,
    REQUIREMENTS_TAB
} from "../utils/constants";
import TaskList from "./TaskList/taskList";
import Header from "./Header/header";
import './main.css'
import RadioTab from "./RadioTabs/radioTab";
import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import AccordionContent from "./Content/accordionContent";
import { getColorBasedOnJobStatus, getColorBasedOnTaskStatus, getJobStatusAsPerTaskStatus, getJobStatusBasedOnTaskStatus, greenStatusValues, JobPositionStatus, redStatusValues } from "./../utils/status";
import Requirements from "./Content/requirement";
import { getBoolean, getCurrentDateStamp, getDateFormat, getDaysFromCertainDays, isArrayEmpty, isEmpty, isNull, isUndefined } from "../utils/utils";
import { dummyData } from "../utils/dummyJson";
import JobPositionList from "./JobPositionList/jobPosition";
import Legends from "./Legends/legends";


function Main() {
    const [appDateFormat, setAppDateFormat] = useState('MM-dd-yyyy');
    const [user, fetchUser] = useState([])
    const [jobPositionList, fetchJobPositionList] = useState([])
    const [taskList, fetchTaskList] = useState([])
    const [requirementList, fetchRequirementList] = useState([])
    const [associatedTaskList, setAssociatedTaskList] = useState([]);
    const [associatedTaskListForJobPositions, setAssociatedTaskListForJobPositions] = useState([]);
    const [selectedTaskOption, setSelectedTaskOption] = useState(null);
    const [selectedTaskOptionUnderJobPositions, setSelectedTaskOptionUnderJobPositions] = useState(null);
    const [isTaskDropdownOpen, setTaskDropdownOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(3);
    const [badgesScanProperties, setBadgesScanProperties] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const getData = async () => {
        var encodedId = getEncodedId();
        // var encodedId = "QW5uZXM=";
        await fetch(api_url, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*',
                "data": "json",
                "dataType": 'jsonp'
            },
            body: JSON.stringify({ encodedId: encodedId })
        }
        )
            .then((res) => res.json())
            .then((res) => {
                setIsFetching(false);
                fetchUser(res["profileDetails"])
                filterRequirementsByExpiryDate(res["requirementList"], res.badgesScanPropertiesResponse);
                setJobStatusByTask(res["jobPositionList"], res.badgesScanPropertiesResponse);
                setTasksByExpiry(res["groupedtaskUnderTaskList"], res.badgesScanPropertiesResponse);
                setBadgesScanProperties(res.badgesScanPropertiesResponse);
            });
    }
    useEffect(() => {
        if (window.location.href.includes("localhost")) {
            getData();
        } else {
            getData();
        }
    }, [])
    function getDummyData() {
        const res = dummyData;
        fetchUser(res["profileDetails"])
        filterRequirementsByExpiryDate(res["requirementList"], res.badgesScanPropertiesResponse);
        setJobStatusByTask(res["jobPositionList"], res.badgesScanPropertiesResponse);
        setTasksByExpiry(res["groupedtaskUnderTaskList"], res.badgesScanPropertiesResponse);
        setBadgesScanProperties(res.badgesScanPropertiesResponse);
    }

    function getMasterJobPositions(list) {

        let masterJob = {
            jobPositionId: "master",
            positionTitle: "All",
            companyId: "All",
            jobPositionTaskStatus: "",
            listOfTask: []
        }
        list.forEach((associatedJobs) => {

            masterJob.listOfTask.push(associatedJobs);

        })
        return masterJob;
    }

    function getMasterTasks(list) {
        var masterTask = {
            taskListId: 'master',
            taskListName: 'All',
            taskListOwner: 'All',
            associatedTaskUnderTaskList: []
        }
        list.forEach((groupTask) => {
            groupTask.associatedTaskUnderTaskList.forEach((associatedTasks) => {
                masterTask.associatedTaskUnderTaskList.push(associatedTasks);
            })
        })
        return masterTask;
    }

    const moveToFrontForTasks = (data, matchingId) => {
        const index = data.findIndex((task) => task.taskListId === matchingId);
        if (index !== -1) {
            const updatedData = [...data];
            updatedData.unshift(...updatedData.splice(index, 1));
            return updatedData;
        }
        return data;
    }

    const moveToFrontForJobPositions = (data, matchingId) => {
        const index = data.findIndex((job) => job.jobPositionId === matchingId);
        if (index !== -1) {
            const updatedData = [...data];
            updatedData.unshift(...updatedData.splice(index, 1));
            return updatedData;
        }
        return data;
    }

    function isDisplayOptionsExpired(displayOptionsExpired) {

        if (displayOptionsExpired === "true") {
            return true
        } else if (displayOptionsExpired === "false") {
            return false
        }
    }


    function filterJobPositionsByExpiredDate(jobPositions, badgesScanProperties) {

        if (!isDisplayOptionsExpired(badgesScanProperties.displayOptionsExpired)) {
            jobPositions.forEach((associatedJob) => {
                !isNull(associatedJob.listOfTask) && associatedJob.listOfTask.forEach((task) => {
                    !isNull(task.listOfRequirement) && task.listOfRequirement.forEach((requirement) => {


                        if (requirement.requirementStatus === JobPositionStatus.REQUIREMENT_EXPIRED) {
                            associatedJob.showJob = false; // Since the requirement is Expired
                        }
                    })
                })
            })
            if (!isArrayEmpty(jobPositions)) {
                jobPositions.push(getMasterJobPositions(jobPositions));
                jobPositions = moveToFrontForJobPositions(jobPositions, 'master');
            }

            fetchJobPositionList(jobPositions);
        } else {
            //Calculating the desired expiry date using showExpiredDays , getting <showExpiredDays> days from today
            var filterFromExpiredDate = getDaysFromCertainDays(parseInt(badgesScanProperties.showExpiredDays));
            let newJobPositions = jobPositions;
            newJobPositions.forEach(
                associatedJob => !isNull(associatedJob.listOfTask) && associatedJob.listOfTask.forEach(
                    task => !isNull(task.listOfRequirement) && task.listOfRequirement.forEach(
                        (requirement) => {
                            setShowRequirementAsPerExpiredDate(requirement, filterFromExpiredDate, parseInt(badgesScanProperties.showExpiredDays));
                        }
                    )
                )
            );

            if (!isArrayEmpty(newJobPositions)) {
                newJobPositions.push(getMasterJobPositions(newJobPositions));
                newJobPositions = moveToFrontForJobPositions(newJobPositions, 'master');
            }

            fetchJobPositionList(newJobPositions);

        }
    }

    function setTasksByExpiry(taskList, badgesScanProperties) {
        filterTasksByExpiredDate(taskList, badgesScanProperties)
    }

    function filterTasksByExpiredDate(taskList, badgesScanProperties) {
        if (!isDisplayOptionsExpired(badgesScanProperties.displayOptionsExpired)) {
            taskList.forEach(
                groupedTask => !isNull(groupedTask.associatedTaskUnderTaskList) && groupedTask.associatedTaskUnderTaskList.forEach(
                    task => !isNull(task.listOfRequirement) && task.listOfRequirement.forEach(

                        (requirement) => {
                            if (requirement.requirementStatus === JobPositionStatus.REQUIREMENT_EXPIRED) {
                                task.showTask = false;
                            }
                        }
                    )
                )
            )
            if (!isArrayEmpty(taskList)) {
                taskList.push(getMasterTasks(taskList));
                taskList = moveToFrontForTasks(taskList, 'master');
            }

            fetchTaskList(taskList);
        } else {
            //Calculating the desired expiry date using showExpiredDays , getting <showExpiredDays> days from today
            var filterFromExpiredDate = getDaysFromCertainDays(parseInt(badgesScanProperties.showExpiredDays));
            taskList.forEach(
                groupedTask => !isNull(groupedTask.associatedTaskUnderTaskList) && groupedTask.associatedTaskUnderTaskList.forEach(
                    task => !isNull(task.listOfRequirement) && task.listOfRequirement.forEach(
                        (requirement) => {
                            setShowRequirementAsPerExpiredDate(requirement, filterFromExpiredDate, parseInt(badgesScanProperties.showExpiredDays));
                        }
                    )
                )
            )
            if (!isArrayEmpty(taskList)) {
                taskList.push(getMasterTasks(taskList));
                taskList = moveToFrontForTasks(taskList, 'master');
            }

            fetchTaskList(taskList);
        }
    }

    function filterRequirementsByCompletionStatus(list, badgesScanProperties) {
        if (badgesScanProperties !== null) {
            if (isDisplayOptionsExpired(badgesScanProperties.displayOptionsExpired)) {
                return list;
            } else {
                return list.filter(e => greenStatusValues.includes(e.requirementStatus))
            }
        }
    }

    function filterRequirementsByExpiryDate(list, badgesScanProperties) {
        var filterFromExpiredDate = getDaysFromCertainDays(parseInt(badgesScanProperties.showExpiredDays));
        list.forEach((requirement) => {

            setShowRequirementAsPerExpiredDate(requirement, filterFromExpiredDate, parseInt(badgesScanProperties.showExpiredDays));
        })

        let tempdata = filterRequirementsByCompletionStatus(list, badgesScanProperties);
        //sorting the requirements based on the requirement name code starts
        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
        const sortedreqs = tempdata.sort((a, b) => collator.compare(a.requirementTitle, b.requirementTitle));
        fetchRequirementList(sortedreqs);
        //sorting the requirements based on the requirement name code ends

        // fetchRequirementList(filterRequirementsByCompletionStatus(list, badgesScanProperties));
    }

    function setShowRequirementAsPerExpiredDate(requirement, filterFromExpiredDate, days) {
        if (redStatusValues.includes(requirement.requirementStatus) && days > 0) { // Filtering by dates only if requirment is expired
            var expiredDate;
            if (!isEmpty(requirement.expiredDate)) {
                expiredDate = new Date(requirement.expiredDate);
                var currentDate = getCurrentDateStamp();
                expiredDate = Date.UTC(expiredDate.getFullYear(), expiredDate.getMonth(), expiredDate.getDate())
                if (expiredDate < filterFromExpiredDate || expiredDate > currentDate) {
                    requirement.showRequirementAsPerExpiredDate = false
                } else { //if requirement lies between current date and filtered date
                    requirement.showRequirementAsPerExpiredDate = true
                }
            } else { // if requirement has no expired date
                requirement.showRequirementAsPerExpiredDate = true
            }
        } else { // if days are not specified by requirement
            requirement.showRequirementAsPerExpiredDate = true
        }
    }

    function setJobStatusByTask(jobPositionList, badgesScanProperties) {

        jobPositionList.forEach((associatedJob) => {
            associatedJob.jobPositionTaskStatus = getJobStatusAsPerTaskStatus(associatedJob.listOfTask);
        })

        filterJobPositionsByExpiredDate(jobPositionList, badgesScanProperties);
    }

    const onTaskOptionChangedForPositionsClicked = (value) => {
        setSelectedTaskOptionUnderJobPositions(value);
        setTaskDropdownOpen(isTaskDropdownOpen);
        for (var i = 0; i < jobPositionList.length; i++) {
            if (value.taskListId === jobPositionList[i].taskListId) {
                let taskListData = jobPositionList[i];
                //sorting the job positions based on the jobposition title code starts
                const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
                const sortedjobpos = taskListData.sort((a, b) => collator.compare(a.positionTitle, b.positionTitle));

                setAssociatedTaskListForJobPositions(sortedjobpos);
                //sorting the job positions based on the jobposition title code code ends
                // setAssociatedTaskListForJobPositions(taskListData)
            }
        }
    }

    const onJobPositionsClicked = (value) => {

        if (value.jobPositionId === "master") {
            let temp_value = value.listOfTask;
            setSelectedTaskOptionUnderJobPositions(value);
            setTaskDropdownOpen(isTaskDropdownOpen);
            let filteredValue = temp_value.filter((job) => jobPositionList.includes(job))
            // setAssociatedTaskListForJobPositions(filteredValue);
            //sorting the job positions based on the jobposition title code starts
            const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
            const sortedjobpos = filteredValue.sort((a, b) => collator.compare(a.positionTitle, b.positionTitle));

            sortedjobpos.forEach((item) => {
                let tempdata = item.listOfTask;
                //sorting the tasks based on the task name code starts
                const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
                const sortedtasks = tempdata.sort((a, b) => collator.compare(a.taskName, b.taskName));
                item.listOfTask = sortedtasks;
                sortedtasks.forEach((subitem) => {
                    let sub_tempdata = subitem.listOfRequirement;
                    //OQCS-5620
                    if(sub_tempdata){
                        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
                        const sortedreqs = sub_tempdata.sort((a, b) => collator.compare(a.requirementTitle, b.requirementTitle));
                        subitem.listOfRequirement = sortedreqs
                    }
                })
            })
            setAssociatedTaskListForJobPositions(sortedjobpos);
            //sorting the job positions based on the jobposition title code ends

        } else {
            setSelectedTaskOptionUnderJobPositions(value);
            setTaskDropdownOpen(isTaskDropdownOpen);
            let taskListData = []
            taskListData.push(value);
            //setAssociatedTaskListForJobPositions(taskListData)
            //sorting the job positions based on the jobposition title code starts
            const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
            const sortedjobpos = taskListData.sort((a, b) => collator.compare(a.positionTitle, b.positionTitle));

            sortedjobpos.forEach((item) => {
                let tempdata = item.listOfTask;
                //sorting the tasks based on the task name code starts
                const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
                const sortedtasks = tempdata.sort((a, b) => collator.compare(a.taskName, b.taskName));
                item.listOfTask = sortedtasks;
                sortedtasks.forEach((subitem) => {
                    let sub_tempdata = subitem.listOfRequirement;
                    if(sub_tempdata){
                        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
                        const sortedreqs = sub_tempdata.sort((a, b) => collator.compare(a.requirementTitle, b.requirementTitle));
                        subitem.listOfRequirement = sortedreqs
                    }
                })
            })
            setAssociatedTaskListForJobPositions(sortedjobpos);
            //sorting the job positions based on the jobposition title code ends
        }
    }

    const onTaskOptionClicked = (value) => {
        setSelectedTaskOption(value);
        setTaskDropdownOpen(isTaskDropdownOpen);
        for (var i = 0; i < taskList.length; i++) {
            if (value.taskListId === taskList[i].taskListId) {
                let taskListData = taskList[i].associatedTaskUnderTaskList;
                //sorting the tasks based on the task name code starts
                const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
                const sortedtasks = taskListData.sort((a, b) => collator.compare(a.taskName, b.taskName));
                sortedtasks.forEach((subitem) => {
                    let sub_tempdata = subitem.listOfRequirement;
                    if(sub_tempdata){
                        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
                        const sortedreqs = sub_tempdata.sort((a, b) => collator.compare(a.requirementTitle, b.requirementTitle));
                        subitem.listOfRequirement = sortedreqs
                    }
                })
                setAssociatedTaskList(sortedtasks);

                //sorting the tasks based on the task name code ends
                // setAssociatedTaskList(taskListData);
            }
        }
    }

    const onTaskTabChange = (value, name) => {
        setSelectedTab(parseInt(value));
    }

    const renderContent = (selectedTab) => {
        switch (selectedTab) {
            case POSITIONS_TAB:
                return <>
                    <JobPositionList jobPositionList={jobPositionList} onOptionClicked={(e) => onJobPositionsClicked(e)} selectedOption={selectedTaskOptionUnderJobPositions} isOpen={isTaskDropdownOpen} />
                    {associatedTaskListForJobPositions.length > 0 && <AccordionContent initialHeaderName={"Job Position Name"} data={associatedTaskListForJobPositions} render={renderAssociatedTasksForJobPositions} />}
                </>
            case TASKS_TAB:
                return <>
                    <TaskList taskList={taskList} onOptionClicked={(e) => onTaskOptionClicked(e)} selectedOption={selectedTaskOption} isOpen={isTaskDropdownOpen} />
                    {associatedTaskList.length > 0 && <AccordionContent initialHeaderName={"Task Name"} data={associatedTaskList} render={renderAssociatedTasksForTaskList} />}
                </>
            case REQUIREMENTS_TAB:
                return <>
                    {requirementList.length > 0 && <Requirements data={requirementList} render={renderRequirements} />}
                </>
            default: break;
        }
    }

    function renderTabs() {
        let tabs = [
            { id: 1, name: 'Positions', checked: false, show: getBoolean(!isNull(badgesScanProperties) ? badgesScanProperties.showJobpostion : "false") },
            { id: 2, name: 'Tasks', checked: false, show: getBoolean(!isNull(badgesScanProperties) ? badgesScanProperties.showTasks : "false") },
            { id: 3, name: 'Requirements', checked: true, show: getBoolean(!isNull(badgesScanProperties) ? badgesScanProperties.showRequirements : "false") }
        ];
        return tabs.filter((tab) => tab.show === true);
    }
    return (
        <>
            {isFetching && (
                //Spinner code
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            )}
            <Header userDetails={user} />
            <Legends />
            <RadioTab onTabChange={(e) => onTaskTabChange(e)}
                selectedTab={selectedTab}
                data={renderTabs()} />
            {renderContent(selectedTab)}
        </>
    );

    function renderRequirements(requirement, requirementIndex) {
        return (
            (requirement.showRequirementAsPerExpiredDate) && <Row key={requirementIndex} className="requirement-row">
                <Col xs={8} md={10}>
                    <div className="requirement-row-title">
                        {requirement.requirementTitle}
                    </div>
                </Col>
                <Col xs={4} md={2}>
                    <div className="requirement-row-completed">
                        {!isNull(requirement.completedDate) && !isEmpty(requirement.completedDate) ? getDateFormat(requirement.completedDate, appDateFormat) : ""}
                    </div>
                </Col>
            </Row>
        );
    }

    function renderAssociatedTasksForJobPositions(jobPosition, jobPositionIndex) {
        let showTaskBasedReqexpiredFlag = true;
        let temp_showTaskBasedReqexpiredFlag = true;
        let showJobBasedTaskexpiredFlag = false;
        let temp_showJobBasedTaskexpiredFlag = false;
        if ((badgesScanProperties.displayOptionsExpired === "true" || badgesScanProperties.displayOptionsExpired === true) && (badgesScanProperties.showExpiredDays === '0' || badgesScanProperties.showExpiredDays === 0)) {
            jobPosition.listOfTask.forEach((task) => {
                if(task.listOfRequirement){
                    task.listOfRequirement.forEach((requirement) => {
                        requirement.showRequirementAsPerExpiredDate = true;
                    })
                }

            })
            showJobBasedTaskexpiredFlag = true;

        } else {
            //To hide the Job row when all the requirements under the task are expired
            jobPosition.listOfTask.forEach((task) => {
                if(task.listOfRequirement){
                    task.listOfRequirement.forEach((requirement) => {

                        if (badgesScanProperties.displayOptionsExpired === "false" || badgesScanProperties.displayOptionsExpired === false) {
                            if (requirement.requirementStatus !== 'Expired') {
                                temp_showTaskBasedReqexpiredFlag = true;
                            }

                            requirement.showRequirementAsPerExpiredDate = true;

                        } else if ((badgesScanProperties.displayOptionsExpired === "true" || badgesScanProperties.displayOptionsExpired === true) && (badgesScanProperties.showExpiredDays !== "0" || badgesScanProperties.showExpiredDays !== 0)) {
                            if (requirement.showRequirementAsPerExpiredDate != undefined && requirement.showRequirementAsPerExpiredDate != 'undefined') {
                                if (requirement.showRequirementAsPerExpiredDate === false) {
                                    temp_showTaskBasedReqexpiredFlag = false;
                                }
                            } else {
                                temp_showTaskBasedReqexpiredFlag = true;
                            }
                        }
                    })
                }
                if (temp_showTaskBasedReqexpiredFlag == false) {
                    showTaskBasedReqexpiredFlag = false;
                }

                task.taskReqExpFlag = showTaskBasedReqexpiredFlag;

            })


            if (isUndefined(jobPosition.showJob) || jobPosition.showJob) {
                jobPosition.listOfTask.forEach((task) => {
                    if (task.taskReqExpFlag === true) {
                        temp_showJobBasedTaskexpiredFlag = true;
                    }

                })
                if (temp_showJobBasedTaskexpiredFlag) {
                    showJobBasedTaskexpiredFlag = true;
                }
            }

        }
        //To hide the Job row when all the requirements under the task are expired
        return (
            (isUndefined(jobPosition.showJob) || jobPosition.showJob) && showJobBasedTaskexpiredFlag &&
            <Accordion.Item eventKey={"KEY-" + jobPositionIndex}>
                <Accordion.Header className={"parent-accordion-header-" + getColorBasedOnJobStatus(jobPosition.jobPositionTaskStatus)}>{jobPosition.positionTitle}</Accordion.Header>
                <Accordion.Body style={{ 'padding': '0 ' }}>
                    <Accordion defaultActiveKey={0} >
                        {
                            jobPosition.listOfTask.map(renderTaskListForJobPositions)

                        }
                    </Accordion>
                </Accordion.Body>
            </Accordion.Item>
        )
    }

    function renderTaskListForJobPositions(task, taskId) {
        return (
            <Accordion.Item eventKey={"KEY-" + taskId}>
                <Accordion.Header className={"child-accordion-header-" + getColorBasedOnTaskStatus(task.taskStatus, task.taskSuspendedStatus)}>
                    <div className="accordion-header-text">
                        {task.taskName}
                    </div>
                </Accordion.Header>
                <Accordion.Body style={{ 'padding': ' 0 ' }}>
                    {
                        task.listOfRequirement != null && task.listOfRequirement.map((requirement, requirementIndex) => {

                            return (
                                requirement.showRequirementAsPerExpiredDate && (
                                    <Row key={requirementIndex} className={"listOfRequirement-row-" + getColorBasedOnTaskStatus(task.taskStatus, task.taskSuspendedStatus)}>
                                        <Col xs={4} md={6}>
                                            <div className="listOfRequirement-title">
                                                {requirement.requirementTitle}
                                            </div>
                                        </Col>
                                        <Col xs={4} md={3}>
                                            <div className="listOfRequirement-completed-title">
                                                {!isNull(requirement.completedDate) && !isEmpty(requirement.completedDate) ? getDateFormat(requirement.completedDate, appDateFormat) : ""}
                                            </div>
                                        </Col>
                                        <Col xs={4} md={3}>
                                            <div className="listOfRequirement-expired-title">
                                                {!isNull(requirement.expiredDate) && !isEmpty(requirement.expiredDate) ? getDateFormat(requirement.expiredDate, appDateFormat) : ""}
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            )
                        })
                    }
                </Accordion.Body>
            </Accordion.Item>
        )
    }

    function renderAssociatedTasksForTaskList(task, taskId) {
        let showTaskBasedReqexpiredFlag = true;
        let temp_showTaskBasedReqexpiredFlag = true;
        if ((badgesScanProperties.displayOptionsExpired === "true" || badgesScanProperties.displayOptionsExpired === true) && (badgesScanProperties.showExpiredDays === '0' || badgesScanProperties.showExpiredDays === 0)) {
            if(task.listOfRequirement){
                task.listOfRequirement.forEach((requirement) => {
                    requirement.showRequirementAsPerExpiredDate = true;
                })
            }
            showTaskBasedReqexpiredFlag = true;

        } else {
            //To hide the task row when all the requirements under the task are expired 

            if (isUndefined(task.showTask) || task.showTask) {
                if(task.listOfRequirement){
                    task.listOfRequirement.forEach((requirement) => {

                        if ((badgesScanProperties.displayOptionsExpired === "false" || badgesScanProperties.displayOptionsExpired === false)) {
                            if (requirement.requirementStatus !== 'Expired') {
                                temp_showTaskBasedReqexpiredFlag = true;
                            }

                            requirement.showRequirementAsPerExpiredDate = true;

                        } else if ((badgesScanProperties.displayOptionsExpired === "true" || badgesScanProperties.displayOptionsExpired === true) && (badgesScanProperties.showExpiredDays !== "0" || badgesScanProperties.showExpiredDays !== 0)) {

                            if (requirement.showRequirementAsPerExpiredDate != undefined && requirement.showRequirementAsPerExpiredDate != 'undefined') {
                                if (requirement.showRequirementAsPerExpiredDate === false) {
                                    temp_showTaskBasedReqexpiredFlag = false;
                                }
                            } else {
                                temp_showTaskBasedReqexpiredFlag = true;
                            }
                        }

                    })
                }
                if (temp_showTaskBasedReqexpiredFlag == false) {
                    showTaskBasedReqexpiredFlag = false;
                }
            }
        }
        //To hide the task row when all the requirements under the task are expired 
        return (
            (isUndefined(task.showTask) || task.showTask) && showTaskBasedReqexpiredFlag &&
            <Accordion.Item eventKey={"KEY-" + taskId}>
                <Accordion.Header className={"task-child-accordion-header-" + getColorBasedOnTaskStatus(task.taskStatus, task.taskSuspendedStatus)}>
                    <div className="accordion-header-text" >
                        {task.taskName}
                    </div>
                </Accordion.Header>
                <Accordion.Body style={{ 'padding': ' 0 ' }}>
                    {

                        task.listOfRequirement != null && task.listOfRequirement.map((requirement, requirementIndex) => {
                            return (

                                requirement.showRequirementAsPerExpiredDate && (<Row key={requirementIndex} className={"listOfRequirement-row-" + getColorBasedOnTaskStatus(task.taskStatus, task.taskSuspendedStatus)}>
                                    <Col xs={4} md={6}>
                                        <div className="task-listOfRequirement-title" >
                                            {requirement.requirementTitle}
                                        </div>
                                    </Col>
                                    <Col xs={4} md={3}>
                                        <div className="listOfRequirement-completed-title">
                                            {!isNull(requirement.completedDate) && !isEmpty(requirement.completedDate) ? getDateFormat(requirement.completedDate, appDateFormat) : ""}
                                        </div>
                                    </Col>
                                    <Col xs={4} md={3}>
                                        <div className="listOfRequirement-expired-title">
                                            {!isNull(requirement.expiredDate) && !isEmpty(requirement.expiredDate) ? getDateFormat(requirement.expiredDate, appDateFormat) : ""}
                                        </div>
                                    </Col>
                                </Row>)


                            )
                        })
                    }
                </Accordion.Body>
            </Accordion.Item>
        )
    }
}

export default Main;